import { useState } from 'preact/hooks';

const ImagePreview = ({file}) => {
  const [src, setSrc] = useState();

  const reader = new FileReader();
      
  reader.onload = () => {
    setSrc(reader.result);
  }
    
  reader.readAsDataURL(file);

  if (!src) {
    return null;
  }

  return (
    <img class="w-1/5 inline-block mr-5 mb-5" src={src} />
  )
}
const ImageUpload = ({images, setImages}) => {
  const onChange = e => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    setImages([...images, file]);
  }

  return (
    <div class="mb-12 self-center w-11/12 lg:w-3/5 rounded overflow-hidden shadow-lg">
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Add Photo or Image</div>
        <label class="cursor-pointer mb-10 text-center block border rounded py-2 px-4 border-black-500 bg-black text-white border-white border-gray-200" for="image">Snap a photo or upload from your library:</label>
        <input id="image" style={{display: 'none'}} onChange={onChange} accept="image/png, image/jpeg" type='file' />
        {images.map(image => <ImagePreview file={image} />)}
      </div>
    </div>
  )
}

export default ImageUpload;
