import { useState } from 'preact/hooks';
import Input from './Input';
import SubmitButton from './SubmitButton';
import request from '../request';

const Reset = () => {
  let [email, setEmail] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [resetSubmitted, setResetSubmitted] = useState(false);

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await request(
        '/reset',
        { method: 'POST', body: JSON.stringify({ email }) }
      );

      setResetSubmitted(true);
    } catch (e) {
      // handle error
    } finally {
      setIsLoading(false);
    }
  }

  const onEmailChange = e => {
    setEmail(e.target.value);
  }

  return (
    <div class="m-auto top-8 w-11/12 lg:w-1/2">
      {!resetSubmitted && (
        <form onSubmit={onSubmit} class="rounded mt-2 bg-white shadow-mr px-8 pt-6 pb-8 mb-4">
          <h2 class="text-xl text-center">Reset Your Password</h2>
          <p class="text-xs text-center">Enter your email address and we'll send you a link to reset your password</p>
          <Input required value={email} onInput={onEmailChange} id="email" label="Email" type="email" placeholder="Email Address" />
          <div class="mt-6 flex items-center justify-between">
            <SubmitButton loading={isLoading}>Continue</SubmitButton>
          </div>
        </form>
      )}
      {resetSubmitted && (
           <div class="p-10 text-center mb-12 mt-12 self-center w-11/12 rounded shadow-lg">
             <p>Thanks! Check your email for instructions to reset your password.</p>
          </div>
      )}
    </div>
  )
}

export default Reset; 
