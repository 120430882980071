import { hasToken } from './utils';
import getConfig from './config';

const config = getConfig();

export default async function request(path, options = {}, multiPart) {
  let headers = {};
  if (!multiPart) {
    headers['Content-Type'] = 'application/json';
  }
  const token = hasToken();

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  const response = await fetch(`${config.apiEndpoint}${path}`, {
    ...options, 
    headers: {
      ...headers,
      ...options.headers
    }
  });
  
  const json = await response.json();
  
  if (!response.ok) {
    throw new Error(json.message);
  }

  return json;
}
