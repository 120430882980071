import 'formdata-polyfill';
import SubmitButton from '../SubmitButton';
import ImageUpload from '../ImageUpload';
import DatePicker from '../DatePicker';


import styles from './index.css';

const SurveyButton = ({selected, children, onClick}) => {
  let className = `${styles.surveyButton} capitalize text-center block border rounded py-2 px-4 w-full `;
  if (selected) {
    className += 'border-black-500 bg-gray-700 text-white border-white border-gray-200';
  } else {
    className += 'border-white border-gray-200 text-black bg-gray-200';
  }

  return (
    <button onClick={onClick} class={className}>{children}</button>
  )
}


const ChoiceSelection = ({isFirst, selectMultiple, options, selectedOptions, onSelectOption, category, displayName}) => {
  return (
    <div class="mb-12 self-center w-11/12 lg:w-3/5 rounded shadow-lg">
      <div class={isFirst ? 'px-6': 'px-6 py-4'}>
        <h2 class="font-bold text-xl mb-2">{displayName}</h2>
        <ul>
          {options.map(option => {
            const isSelected = () => {
              if (selectMultiple) {
                if (selectedOptions[category]) {
                  return selectedOptions[category].some(c => c === option);
                }
                return false;
              } 

              return selectedOptions[category] === option 
            }

            return (
              <li class="p-2 w-2/4 inline-block mb-2">
                <SurveyButton selected={isSelected()} onClick={() => onSelectOption(category, option)}>{option}</SurveyButton>
              </li>
            )
          })}
          {category === 'urgency'
            ? (
              <li class="w-full p-2 inline-block mb-2">
                <DatePicker onSelect={(date) => onSelectOption(category, date)} />
              </li>
            ) 
            : null 
          }
        </ul>
      </div>
    </div>
  )
}

const TextInput = ({placeholder, category, displayName, onSelectOption}) => (
<div class="mb-12 self-center w-11/12 lg:w-3/5 rounded overflow-hidden shadow-lg">
  <div class="px-6 py-4">
    <h2 class="font-bold text-xl mb-2">{displayName}</h2>
    <textarea placeholder={placeholder} onInput={e => onSelectOption(category, e.target.value)} class="p-2 w-full border border-black rounded h-40" />
  </div>
</div>
)


const Survey = ({isLoading, images, setImages, selectedOptions, data, onSelectOption, onSubmit}) => {
  return (
    <div class={`${styles.disableDoubleTapZoom} pb-8 flex flex-col w-full`}>
      <div class="self-center m-10">
        <h2 class="text-2xl text-center">Tap freely.</h2>
      </div>
      {data.map(({displayName, placeholder, category, type, options, selectMultiple}, index) => {
        if (type === 'text') {
          return (
            <TextInput placeholder={placeholder} onSelectOption={onSelectOption} category={category} displayName={displayName} />
          )
        }
        
        return (
          <ChoiceSelection 
            selectedOptions={selectedOptions} 
            options={options} 
            category={category}
            displayName={displayName}
            onSelectOption={onSelectOption} 
            selectMultiple={selectMultiple} 
            isFirst={index === 0} />
        )
      })}
      <ImageUpload images={images} setImages={setImages} />
      <div class="px-8 flex justify-center">
        <SubmitButton loading={isLoading} onSubmit={onSubmit}>Submit</SubmitButton>
      </div>
    </div>
  )
}

export default Survey;
