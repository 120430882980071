const getApiEndpoint = () => {
  if (typeof window !== 'undefined') {
    return document.location.hostname === 'localhost' ? 'http://localhost:3000/api' : 'https://fabric-tap.herokuapp.com/api'
  }

  return 'https://fabric-tap.herokuapp.com/api';
};

export default () => {
  return {
    apiEndpoint: getApiEndpoint()
  }
}
