import { Fragment } from 'preact';
import { Link } from 'preact-router/match';
import LoginForm from '../LoginForm/index';

const UnauthenticatedHome = ({onUserLogin}) => {
	return (
		<Fragment>
			<LoginForm onUserLogin={onUserLogin} />
		</Fragment>
	)
}

const Home = ({ user, onUserLogin}) => {
  return user 
    ? (
      <div class="m-auto p-4 container md:py-20">
        <Link class="block text-center border rounded m-6 py-2 px-4 border-black-500 bg-black text-white border-white border-gray-200" href="/start-request">Start a Request</Link>
        <Link class="block text-center border rounded m-6 py-2 px-4 border-black-500 bg-black text-white border-white border-gray-200" href="/request-history">Request History</Link> 
        <div class="self-center m-10">
          <p class="mb-6 text-sm">Welcome to FabricTap, an interactive and efficient way to request fabrics of any kind, at any moment, from any device, in a matter of minutes.</p>
          <p class="mb-6 text-sm">When you‘re in need of fabric and don’t have time for a lengthy email, our solution is simple: sign in + start a request + tap what you need.</p>
          <p class="mb-6 text-sm">As soon as you submit the request we’ll send out a confirmation email with a recap and start sourcing fabric options that best meet your needs.</p>
          <p class="mb-6 text-sm">Your selection will be flying over an ocean shortly after that and landing on your desk when you need it. It’s fabric research, made simple.</p>
        </div>
      </div>
    )
    : (
      <UnauthenticatedHome onUserLogin={onUserLogin} />
    )
}

export default Home;
