import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import Input from '../Input';
import request from '../../request';
import SubmitButton from '../SubmitButton';

const Register = ({ onUserRegister }) => {
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [company, setCompany] = useState('');
  let [email, setEmail] = useState('');
  let [streetAddress, setStreetAddress] = useState('');
  let [city, setCity] = useState('');
  let [state, setState] = useState('');
  let [zipCode, setZip] = useState('');
  let [password, setPassword] = useState('');
  let [registrationError, setRegistrationError] = useState(false);

  let [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    setRegistrationError(false);
    try {
      const body = JSON.stringify({
        user: {
          firstName,
          lastName,
          company,
          email,
          address: {
            streetAddress,
            city,
            state,
            zipCode
          },
          password
        }
      });

      const response = await request(
        '/users',
        { method: 'POST', body }
      );

      onUserRegister(response.user, '/start-request');
    } catch (e) {
      setRegistrationError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div class="m-auto top-8 w-11/12 lg:w-1/2">
      <form onSubmit={onSubmit} class="rounded mt-2 bg-white shadow-mr px-8 pt-6 pb-8 mb-4">
        <h2 class="text-xl text-center">Register</h2>
        <Input required value={firstName} onInput={e => setFirstName(e.target.value)} id="firstName" label="First Name" placeholder="First Name" />
        <Input required value={lastName} onInput={e => setLastName(e.target.value)} id="lastName" label="Last Name" type="text" placeholder="Last Name" />
        <Input value={company} onInput={e => setCompany(e.target.value)} id="company" label="Company" type="text" placeholder="Company" />
        <Input required value={email} onInput={e => setEmail(e.target.value)} id="email" label="Email Address" type="email" placeholder="Email Address" />
        <Input value={streetAddress} onInput={e => setStreetAddress(e.target.value)} id="streetAddress" label="Send Packages To This Address" type="text" placeholder="Street Address" />
        <Input value={city} onInput={e => setCity(e.target.value)} id="city" label="City" type="text" placeholder="City" />
        <Input value={state} onChange={e => setState(e.target.value)} id="state" label="State" type="select">
          <option selected>-- select state --</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </Input>
        <Input onChange={e => setZip(e.target.value)} value={zipCode} id="zipCode" label="Zip" type="text" placeholder="90210" />
        <Input required onChange={e => setPassword(e.target.value)} value={password} id="password" label="Password" type="password" placeholder="***********" />
        <div>
          <SubmitButton fullWidthDesktop={true} loading={isLoading}>
            Register
          </SubmitButton>
          {registrationError && <p class="text-red-500 text-xs italic">A user with that email address already exists.</p>}
          <Link class="text-center w-full inline-block align-baseline font-bold text-sm text-black-500 hover:underline" href="/">
            Already a user? Log In Here
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Register;
