import styles from './index.css';



const Loader = () => (
  <div class="flex items-center flex-col">
    <div class={`${styles.loaderDots} block relative w-20 h-5`}>
      <div class="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white" />
      <div class="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white" />
      <div class="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white" />
      <div class="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white" />
    </div>
  </div>
);

const SubmitButton = ({onSubmit, children, fullWidthDesktop, loading }) => (
  <button
    disabled={loading} 
    onClick={onSubmit}
    type="submit" 
    class={`self-center w-full ${fullWidthDesktop ? '' : 'lg:w-1/5'} text-center block rounded py-2 px-4 bg-black text-white`}
  >
    {loading ? <Loader /> : children}
  </button>
)
export default SubmitButton;
