import { useState } from 'preact/hooks';
import { Link } from 'preact-router';
import surveyData from '../surveyData';

const MySurveys = ({user}) => {
  if (!user) {
    return null;
  }

  const Submission = ({submission}) => {
    let [expanded, setExpanded] = useState(false);
   
    const renderSubmission = (submission) => {
      const submissions = Object.keys(submission)
        .filter(key => {
          switch (key) {
            case "_id":
            case "createdAt":
            case "updatedAt":
              return false;
          }
          return Boolean(submission[key].length)
        })
        .map(key => {
          if (key === 'images') {
            return (
              <li class="p-5">
                <p>Images:</p> 
                {submission[key].map(src => <img class="p-5 w-2/5" src={src} />)}
              </li>
            )
          }
          
          const category = surveyData.find(c => c.category === key);
          
          if (category) {
            return <li class="p-5">{`${category.displayName}: ${Array.isArray(submission[key]) ? submission[key].join(', ') : submission[key]}`}</li>;
          }
        }
      );
  
      return <ul class="bg-gray-200">{submissions}</ul>;
    }
 
    const submissionAnswers = Object.keys(submission)
        .filter(key => {
          switch (key) {
            case "_id":
            case "createdAt":
            case "updatedAt":
              return false;
          }
  
          return Boolean(submission[key].length)
        })

    if (!submissionAnswers.length) {
      return null;
    }

    return (
      <li class="mb-6 shadow-md">
        <button onClick={() => setExpanded(!expanded)} class="p-5 w-full flex justify-between">
          Submitted: {new Date(submission.createdAt).toLocaleDateString()}
          <div class="text-gray-700">
            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
        </button>
        {expanded && renderSubmission(submission)}
      </li> 
    )
  }

  const submissionsReversed = user.submissions.reduce((previous, current) => {
    return [current, ...previous];
  }, [])
  
  return (
      <div class="p-4 m-auto container">
        <div class="mb-8">
          <div class="flex justify-between">
            <h1 class="w-full text-xl text-bold">Past Requests</h1>
            <Link class="w-full lg:w-2/6 text-center block border rounded py-2 px-4 border-black-500 bg-black text-white border-white border-gray-200" href="/start-request">Start a Request</Link>
          </div>
        </div>
        <ul>
          {submissionsReversed.map(submission => (<Submission submission={submission} />))}
        </ul>
      </div>
  )
}

export default MySurveys;
