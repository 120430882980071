import styles from './input.css';

const Input = ({required, children, value, onInput, onChange, id, label, type, placeholder }) => {
  if (type === "select") {
    return (
      <div class="mb-4 relative">
        <label class="block uppercase tracking-wide focus:text-gray-800 text-gray-700 text-xs font-bold mb-2" for={id}>
          {label}
        </label>
          <select required={required} value={value} onChange={onChange} class="focus:border-gray-900 block appearance-none w-full border text-gray-700 py-3 px-4 pr-8 rounded leading-tight outline-none bg-white border-gray-500" id={id}>
            {children}
          </select>
          <div class={`${styles.selectArrow} pointer-events-none absolute flex items-center px-2 text-gray-700`}>
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
      </div>
    )
  }

  return (
    <div class="mb-4">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for={id}>
        {label}
      </label>
      <input required={required} value={value} onInput={onInput} onChange={onChange} class="block appearance-none w-full border text-gray-700 py-3 px-4 pr-8 rounded leading-tight outline-none bg-white border-gray-500 focus:border-gray-900" id={id} type={type} placeholder={placeholder} />
    </div>
  )
}
export default Input;
