import { useState } from 'preact/hooks';
import { Link } from 'preact-router';
import Input from '../Input';
import SubmitButton from '../SubmitButton';
import request from '../../request';

import styles from './index.css';

const Login = ({ onUserLogin }) => {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [loginError, setLoginError] = useState();
  let [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await request(
        '/users/login',
        { method: 'POST', body: JSON.stringify({ user: { email, password } }) }
      );

      onUserLogin(response.user);
    } catch (e) {
      setLoginError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const onEmailChange = e => {
    setEmail(e.target.value);
  }

  const onPasswordChange = e => setPassword(e.target.value);

  return (
    <div class="m-auto top-8 w-11/12 lg:w-1/2">
      <form onSubmit={onSubmit} class="rounded mt-2 bg-white shadow-mr px-8 pt-6 pb-8 mb-4">
        <h2 class="text-xl text-center">Sign in</h2>
        <Input required value={email} onInput={onEmailChange} id="email" label="Email" type="email" placeholder="Email Address" />
        <Link href="/reset" class="hover:underline text-xs float-right">Forgot your password?</Link>
        <Input required value={password} onInput={onPasswordChange} id="password" label="Password" type="password" placeholder="***********" />
        {loginError && <p class="text-red-500 italic">Incorrect email and/or password</p>}
        <div class="mt-6 flex items-center justify-between">
          <SubmitButton loading={isLoading}>Login</SubmitButton>
        </div>
      </form>
      <span class="flex justify-center border-t"><span class={`px-2 relative ${styles.top}`}>or</span></span>
      <Link
        class="mt-1 self-center w-3/5 m-auto text-center block rounded py-2 px-4 bg-black text-white"
        href="/register"
      >
        Register
      </Link>
    </div>
  )
}

export default Login; 
