import { Fragment } from 'preact';
import { Router, route } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import Home from './Home';
import Survey from './Survey';
import Header from './Header';
import PastRequests from './PastRequests';
import Register from './Register';
import request from '../request';
import surveyData from './surveyData';
import SubmissionConfirmation from './SubmissionConfirmation';
import Footer from './Footer';
import ResetPassword from './ResetPassword';
import ResetForm from './Reset';

const App = () => {
	let [selectedOptions, setSelectedOptions] = useState({});
	let [images, setImages] = useState([]);
	let [user, setUser] = useState();
  let [hasCheckedUser, setHasCheckedUser] = useState(false);
  let [isSurveyLoading, setIsSurveyLoading] = useState(false);
  let [renderFooter, setRenderFooter] = useState(false);

  let [showSubmissionConfirmation, setShowSubmissionConfirmation] = useState(false);

	const handleRoute = e => {
    window.scrollTo(0, 0);
    setSelectedOptions({});
    setImages([]);
    mixpanel.track(`Page - ${e.url}`, { page: e.url, user});
    if (e.url !== '/' && showSubmissionConfirmation) {
      setShowSubmissionConfirmation(false);
    }
    
    if (e.url === '/') {
      setRenderFooter(true);
    } else {
      setRenderFooter(false);
    }


    switch (e.url) {
			case '/register':
				if (user) {
					route('/');
				}
				break;
		  case '/start-request':
      case '/request-history':
				if (!user) {
					route('/');
				}
				break;
		}
	}
	
	const handleUserAuthentication = (user, to = '/') => {
		localStorage.setItem('token', user.token);
    setUser(user);
    Promise.resolve(route(to));
	};
  
	const onSelectOption = (selectedCategory, option) => {
		const { selectMultiple } = (surveyData.find(c => c.category === selectedCategory && c.selectMultiple) || {});
    
    if (selectMultiple) {
			if (!selectedOptions[selectedCategory]) {
				return setSelectedOptions({
					...selectedOptions, 
					[selectedCategory]: [option]
				});
			}
			
			const optionExists = selectedOptions[selectedCategory].find(o => o === option);
			if (optionExists) {
        return setSelectedOptions({
					...selectedOptions, 
					[selectedCategory]: selectedOptions[selectedCategory].filter(o => o !== option)
				});
			}
			
			return setSelectedOptions({
				...selectedOptions, 
				[selectedCategory]: [...selectedOptions[selectedCategory], option]
			});
		}
		
		setSelectedOptions({
			...selectedOptions, 
			[selectedCategory]: option 
		});
	}

	const onSurveySubmit = async () => {
    setIsSurveyLoading(true);
    const formData = new FormData();
  
    for (let key of Object.keys(selectedOptions)) {
      formData.append(key, selectedOptions[key]);
    }
    
    for (let image of images) {
      formData.append(image.name, image, image.name)
    }

    try {
       await request(
        '/users/survey', 
        { method: 'POST', body: formData },
        true
      );
      route('/');
      setShowSubmissionConfirmation(true);
      
    } catch(error) {
      console.log(error);
    } finally {
      setIsSurveyLoading(false);
    }
	}

	const onLogout = () => {
		localStorage.removeItem('token');
		setUser();
		route('/');
	}

  useEffect(() => {
    if (!hasCheckedUser) {
			(async () => {
        try {
          const response = await request('/user');
          setUser(response.user);
          mixpanel.identify(response.user.email);
          mixpanel.people.set({
            $email: response.user.email, 
          });
        } catch (error) {
          if (error.message === 'invalid signature') {
            setUser();
            localStorage.removeItem('token');
          }
        } finally {
          setHasCheckedUser(true);
        }
			})()
    }
	}, []);

  if (!hasCheckedUser) {
    return null;
  }

	return (
		<Fragment>
      <Header onLogout={onLogout} user={user} />
      {showSubmissionConfirmation && <SubmissionConfirmation />}
      <div class="justify-center flex-grow">
        <Router onChange={handleRoute}>
          <Home user={user} onUserLogin={handleUserAuthentication} path="/" />
          <Register onUserRegister={handleUserAuthentication} path="/register" />
          <Survey
            isLoading={isSurveyLoading}
            onSubmit={onSurveySubmit} 
            selectedOptions={selectedOptions} 
            onSelectOption={onSelectOption} 
            data={surveyData} 
            path="/start-request" 
            images={images}
            setImages={setImages}
          />
          <PastRequests user={user} path="/request-history" />
          {!user && <ResetForm path="/reset" /> }
          {!user && <ResetPassword onPasswordReset={handleUserAuthentication} path="/reset/:token" /> }
        </Router>
      </div>
      {renderFooter && <Footer />}
		</Fragment>
	);
}

export default App;
