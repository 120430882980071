import { useState } from 'preact/hooks';
import Input from './Input';
import SubmitButton from './SubmitButton';
import request from '../request';

const ResetPassword = ({ token, onPasswordReset }) => {
  let [password, setPassword] = useState('');
  let [confirmedPassword, setConfirmedPassword] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [validationError, setValidationError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    
    if (!validate()) {
      setPassword('');
      setConfirmedPassword('');
      setIsLoading(false);
      setValidationError(true);
      return;
    }
    
    try {
      const response = await request(
        '/users/password-reset',
        { method: 'POST', body: JSON.stringify({  password, token } )}
      );


      setPassword('');
      setConfirmedPassword('');
      onPasswordReset(response.user);

    } catch (error) {
      //TODO: handle error
    } finally {
      setIsLoading(false);
    }
  }

  const onPasswordChange = e => setPassword(e.target.value);
  const onConfirmedPasswordChange = e => setConfirmedPassword(e.target.value);
  const validate = () => password === confirmedPassword;

  return (
    <form onSubmit={onSubmit} class="m-auto top-8 w-11/12 lg:w-1/2 rounded mt-2 bg-white shadow-mr px-8 pt-6 pb-8 mb-4">
      <h2 class="text-xl text-center">Reset Password</h2>
      <Input required value={password} onInput={onPasswordChange} id="password" label="Password" type="password" placeholder="***********" />
      <Input required value={confirmedPassword} onInput={onConfirmedPasswordChange} id="confirmed-password" label="Confirm Password" type="password" placeholder="***********" />
      {validationError && <p class="text-red-500 italic">Passwords do not match. Please try again.</p>}
      <SubmitButton loading={isLoading}>Reset Password</SubmitButton>
    </form>
  )
}

export default ResetPassword; 
