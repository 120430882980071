import { useState, useEffect } from 'preact/hooks';


const DatePicker = ({onSelect}) => {
  const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const today = new Date();

  let [month, setMonth] = useState(today.getMonth());
  let [year, setYear] = useState(today.getFullYear());

  let [datePickerValue, setDatePickerValue] = useState('Select Date');
  let [showDatePicker, setShowDatePicker] = useState(false);
  let [dateValue, setDateValue] = useState('');
  let [numberOfDays, setNumberOfDays] = useState([]);

  const getDateValue = (date) => {
    let selectedDate = new Date(year, month, date);
    setDatePickerValue(selectedDate.toDateString());
    setDateValue(selectedDate.getFullYear() + "-" + ('0' + selectedDate.getMonth()).slice(-2) + "-" + ('0' + selectedDate.getDate()).slice(-2));
    setShowDatePicker(false);
    onSelect(selectedDate.toDateString());
  }

  const isToday = (date) => {
    const today = new Date();
    const d = new Date(year, month, date);
    return today.toDateString() === d.toDateString();
  }

  const getNoOfDays = () => {
    let firstDayIndex = new Date(year, month, 0).getDay();
    let daysInMonth = new Date(year, month, 0).getDate();
    
    let daysArray = [];
    for (let i = 0; i <= firstDayIndex; i++) {
      daysArray.push(null);
    }
    
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    
    setNumberOfDays(daysArray);
  }

  useEffect(() => {
    getNoOfDays();
  }, [month, year])

  return (
    <div class="mb-5 w-64">
      <label for="datepicker" class="font-bold mb-1 text-gray-700 block">Need By:</label>
      <div class="relative">
        <input type="hidden" name="date" value={dateValue} />
        <input type="text" value={datePickerValue} onClick={() => setShowDatePicker(!showDatePicker)} onKeyDown={e => { e.key === 'Escape' && setShowDatePicker(false) }} class="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium" placeholder="Select date" />
        <div class="absolute top-0 right-0 px-3 py-2">
          <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
        </div>
        {showDatePicker && (
          // todo: click away from datepicker to dismiss
          <div class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0" style="width: 17rem">
            <div class="flex justify-between items-center mb-2">
              <div>
                <span class="text-lg font-bold text-gray-800">{MONTH_NAMES[month]}</span>
                <span class="ml-1 text-lg text-gray-600 font-normal">{year}</span>
              </div>
              <div>
                <div>
                  <button
                    type="button"
                    class={`transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full ${month === 0 ? 'cursor-not-allowed opacity-25' : ''}`}
                    disabled={month == 0}
                    onClick={() => {
                      setMonth(month - 1);
                    }}
                  >
                    <svg class="h-6 w-6 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button
                    type="button"
                    class={`focus:outline-none focus:shadow-outline transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full ${month === 11 ? 'cursor-not-allowed opacity-25' : ''}`}
                    disabled={month == 11}
                    onClick={() => {
                      setMonth(month + 1);
                    }}
                  >
                    <svg class="h-6 w-6 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap mb-3 -mx-1">
              {DAYS.map(day => (
                <div style="width: 14.26%" class="px-1">
                  <div class="text-gray-800 font-medium text-center text-xs">{day}</div>
                </div>
              ))}
            </div>
            <div class="flex flex-wrap -mx-1">
              {numberOfDays.map((date) => {
                if (!date) {
                  return <div style="width: 14.28%" class="text-center border p-1 border-transparent text-sm" />;
                }
                
                return (
                  <div
                    style="width: 14.28%"
                    onClick={() => getDateValue(date)}
                    class={`${isToday(date) === true ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200'} cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100`}
                  >{date}</div>
                )
              })}
            </div>
          </div>
        )}
    </div>
    </div>
  )
}

export default DatePicker;
