import { Link } from 'preact-router/match';
import styles from './index.css';

const UnauthenticatedHeader = () => (
  <header class="justify-center w-full flex bg-black p-4">
    <Link href="/"><img class={styles.logo} src="/assets/logo.png" /></Link>
  </header>
);

const AuthenticatedHeader = ({ onLogout }) => (
  <header class="justify-between w-full flex bg-black p-3">
    <Link class="w-56" href="/"><img class={styles.logo} src="/assets/logo.png" /></Link>
    <div class="float-right">
      <button onClick={onLogout} class="border-2 border-solid border-white text-white font-bold py-2 px-4 hover:underline">
        Log Out
      </button>
    </div>
  </header>
);

const Header = ({ user, onLogout }) => {
  if (!user) {
    return  <UnauthenticatedHeader />;
  }

  return  <AuthenticatedHeader onLogout={onLogout} />
}

export default Header;
